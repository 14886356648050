import breakpoints from 'constants/breakpoints';

import styled from 'styled-components';

import BowIcon from '~assets/bg/digital-frontiers.svg';
import {Container as TextContainer} from '~atoms/TextContainer/styles';

export const Container = styled.div`
    position: relative;
    z-index: 1;

    ${breakpoints.aboveTablet} {
        p {
            margin-top: 32px;
            max-width: 648px;
        }
    }

    ${TextContainer} {
        margin-top: 0;
    }
`;

export const BowContainer = styled.div`
    pointer-events: none;
    position: absolute;
    z-index: -1;
    overflow-x: hidden;
    top: -320px;
    height: 1000px;
    left: 0;
    right: 0;

    ${breakpoints.aboveTablet} {
        top: -400px;
    }
`;

export const Bow = styled(BowIcon)`
    position: absolute;
    top: 0;
    left: 0;
    --size: 400px;
    width: var(--size);
    aspect-ratio: 290 / 740;

    ${breakpoints.aboveTablet} {
        --size: 766px;
    }
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 24px;
    margin: 24px 0 16px;

    ${breakpoints.abovePhoneS} {
        gap: 56px;
    }
`;

export const TradeButton = styled.a`
    color: #ee4492;
    display: flex;
    align-items: center;
    text-align: center;
    height: 56px;
    font-weight: 400;
    padding: 0 24px;
    cursor: pointer;
    border: 1px solid currentColor;
    border-radius: 16px;
    text-decoration: none;
    white-space: nowrap;

    gap: 6px;
`;

export const ChartButton = styled.a`
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;

    svg {
        flex-shrink: 0;
        margin-left: 13px;
    }
`;
