import Image from 'next/image';

import Text from '~components/atoms/Text';
import TextContainer from '~components/atoms/TextContainer';
import {PageQueryPageDataAttributesContentComponentSectionTitleSection} from '~interfaces/graphql/PageQuery';
import domeIcon from '~assets/icons/logo.png';
import ArrowRightIcon from '~assets/icons/arrow-right-small.svg';

import {
    Bow,
    BowContainer,
    ButtonsContainer,
    ChartButton,
    Container,
    TradeButton,
} from './styles';

const TitleSection = ({
    title,
    gradientTitle,
    text,
    tradeButtonLabel,
    tradeButtonUrl,
    chartButtonLabel,
    chartButtonUrl,
}: PageQueryPageDataAttributesContentComponentSectionTitleSection) => {
    return (
        <>
            <Container>
                <BowContainer>
                    <Bow />
                </BowContainer>
                <TextContainer size="large">
                    <Text type="largeSectionTitle" noMarginBottom>
                        {gradientTitle && gradientTitle.length > 0
                            ? `${title} `
                            : title}
                        <Text type="largeSectionTitle" color="gradient" inline>
                            {` ${gradientTitle} `}
                        </Text>
                    </Text>
                    <Text type="content">{text}</Text>
                    <ButtonsContainer>
                        <TradeButton
                            href={tradeButtonUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image src={domeIcon} width={24} height={24} />
                            {tradeButtonLabel}
                        </TradeButton>
                        <ChartButton
                            href={chartButtonUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {chartButtonLabel}
                            <ArrowRightIcon />
                        </ChartButton>
                    </ButtonsContainer>
                </TextContainer>
            </Container>
        </>
    );
};

export default TitleSection;
